<template>
  <v-app id="inspire">
    <v-main>
      <div id="auth">
        <router-view></router-view>
      </div>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "Auth",
};
</script>

<style></style>
